import axios from "axios"

export const createCardResponseAudio = async (cardResponseId, fileData, duration) => {
  const formData = new FormData();
  formData.append("file", fileData, "recording.mp3")
  formData.append("duration", duration)
  try {
    return await axios.post(`/card_responses/${cardResponseId}/audio_recordings`, formData);
  } catch (error) {
    const rollbarInfo = {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers,
      request: error.request,
      config: error.config,
    };
    Rollbar.error("Audio upload failed", rollbarInfo);
  }
}
