import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Button from "./Button"
import { createWrittenResponse } from "../api/writtenResponses"
import { initSketch } from "../vendor/sketch"

const WrittenResponse = ({ cardResponseId, onCompleted, onCancel }) => {
  const canvasContainer = useRef()
  let sketch

  const clear = () => {
    if (sketch) {
      sketch.clear()
    }
  }

  const saveWrittenResponse = () => {
    const imageData = sketch?.canvas.toDataURL()
    createWrittenResponse(cardResponseId, imageData).then(() => {
      onCompleted(imageData)
    }).catch((reason) => {
      Rollbar.error('Error when uploading written response.', reason);
      console.error(reason)
      alert("There was an error uploading the written response")
    })
  }

  useEffect(() => {
    if (!sketch && canvasContainer.current)  {
      sketch = initSketch(canvasContainer.current)
    }
    return () => {
      if (sketch) sketch.remove()
    }
  }, [canvasContainer.current])

  return (
    <div className="written-response">
      <div className="row">
        <div className="col-md-12 mb-3">
          <h5>Write your answer</h5>
          <div className="canvas-container" ref={canvasContainer} />
        </div>
        <div className="col-md-6">
          <Button text="Submit Response" onClick={saveWrittenResponse} />
        </div>
        <div className="col-md-3 px-1">
          <Button icon="eraser" text="Clear Response" onClick={clear} variant="outline-primary" />
        </div>
        <div className="col-md-3">
          <Button icon="arrow-left" text="Go Back" onClick={onCancel} variant="outline-primary" />
        </div>
      </div>
    </div>
  )
}

WrittenResponse.propTypes = {
  cardResponseId: PropTypes.number.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default WrittenResponse
